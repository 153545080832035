.a{
      /* width: 100vw; */
  min-height: 30vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #150f0f;
    
}
.a-title{
    font-size: 26px;
    text-align: center;

}
.a-info{
    margin: 3rem;
    font-weight: 200;
    font-size: 22px;
    text-align:justify;
    gap: 2rem;
}
:root        { --min-pad: 1rem }

[a-info]     { padding: max(var(--min-pad),calc(5vmin - 18px))
                        max(var(--min-pad),calc(35vw - 224px)) }

[a-info="1"] { padding: var(--min-pad) } /* minimal padding */
[a-info="0"] { padding: 0 }              /* no padding */

/*****************************/
/* [S]hading and [A]nimation */
/*****************************/
/* Element [S]hading custom properties */
[S] {
    /* defaults */
    --clr-shadow: 0 0% 0%; /* HSL Black */

    /* GMD elevation 1dp, d - default */
    --elevate-d: 0px 2px 1px -1px hsl(var(--clr-shadow) / .20),
                 0px 1px 1px  0px hsl(var(--clr-shadow) / .14),
                 0px 1px 3px  0px hsl(var(--clr-shadow) / .12);

    /* when hovered */
    /* GMD elevation 3dp, h - hovered */
    --elevate-h: 0px 3px 3px -2px hsl(var(--clr-shadow) / .20),
                 0px 3px 4px  0px hsl(var(--clr-shadow) / .14),
                 0px 1px 8px  0px hsl(var(--clr-shadow) / .12);
}

[S="alt1"] { /* some alternative shading, just testing */
    --elevate-d: 0 2px 30px 0 hsl(var(--clr-shadow) / .25);
    --elevate-h: 0 2px 50px 0 hsl(var(--clr-shadow) / .7);
}

/* Element [A]nimation custom properties */
[A] {
    /* defaults */
    --clr-inset: 0 0% 0% / 1; /* M3 theme default, black inset as outline (a11y) */

    --a-opa-d  : 0.85; /* opacity */
    --a-scale-d: 1   ; /* scale   */

    /* when hovered */
    --a-opa-h  : 1   ; /* opacity when animated hover */
    --a-scale-h: 1.02; /* scale when animated and hovered */

    --outline-d: inset 0 0 0 2px transparent;           /* default */
    --outline-h: inset 0 0 0 2px hsl(var(--clr-inset)); /* hover   */
}
/* [S]hading and [A]nimation defaults */
[S]     { box-shadow: var(--elevate-d) }
[A]     { opacity   : var(--a-opa-d)   }
[S][A]  { box-shadow: var(--elevate-d), var(--outline-d) }

/* [A]nimation actions */
[A]:hover {
  opacity: var(--a-opa-h);
  transform: scale(var(--a-scale-h));
  text-shadow: none;
  cursor: pointer
}

[A]:active:not(:focus), [A]:focus { transform: scale(var(--a-scale-d)) }

/* [S]hading actions, but only when [A]nimated */
[S][A]:hover                            { box-shadow: var(--elevate-h) }
[S][A]:active:not(:focus), [S][A]:focus { box-shadow: var(--elevate-d), var(--outline-h) }

[S][A]:active:not(:focus) header,
[S][A]:active:not(:focus) footer { 
    box-shadow: var(--outline-h);
} /* outline only, no shadow below/above */

