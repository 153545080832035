.c{
    height: 100vh;
    position: relative;
}
.c-bg{
    width: 20px;
    height: 105%;
    background-color: rgb(255, 143, 14);
    position: absolute;
}
.c-wrapper{
    padding: 50px;

}
.c-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.c-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.c-title{
    font-size: 60px;
    width: 80% ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.c-info-item{
    display: flex;
    align-items: center;
    margin: 10px 0px;
    font-weight: 300;
}
.c-icon{
    width: 20px; 
    height: 20px;
    margin-right: 3px;
}
.c-desc{
    font-weight: 200;
    font-size: 22px;
}
form{
    margin-top: 20px; 
}
input{
    width: 60%;
    height: 50px;
    border: none;
    border-bottom: 1px solid rgb(255, 143, 14);
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}
textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px; 
    border: 1px solid rgb(255, 143, 14);

}
button{
    border: none;
    padding: 15px ;
    background-color: rgb(255, 143, 14);
    color: #ffff;
    font-weight: 500;
    cursor: pointer;
    margin-right: 20px;
}
@media screen and (max-width: 820px){
    .c{
        margin-top: 35rem;
    }
}